import axios from "../../index";
import history from "../../../history";

export default function exchange(token){
    let promise = new Promise(function(resolve,reject){
        axios.post('/api-auth/exchange',{
        },{
            headers:{
                Authorization: `Bearer ${token}`
            }
        }).then(res =>{
            if(res){
                axios.defaults.headers.common['Authorization'] = 'Bearer '+res.data.data.token
            }
            localStorage.setItem('_a7as__tt56uid',res.data.data.user.id)
            localStorage.setItem('__xtropt',res.data.data.token_old)
            resolve(res)
        }).catch(err =>{
            reject(err)
        })
    })
    

    return promise
}