import axios from "../../index";
import history from "../../../history";

export default function logout(url = '/'){
    let promise = new Promise(function(resolve,reject){
        axios.post('/api-auth/logoutUser',{
        })
        // .then(res =>{
        //     axios.post(`${process.env.REACT_APP_AUTH_URL}/session/destroy`,{
        //     },{
        //         withCredentials:true
        //     }).finally(res=>{
        //         localStorage.clear()
        //     })
        //     resolve(res)
        // }).catch(err =>{
        //     axios.post(`${process.env.REACT_APP_AUTH_URL}/session/destroy`,{
        //     },{
        //         withCredentials:true
        //     }).finally(res=>{
        //         localStorage.clear()
        //     })
        //     reject(err)
        // })
        .finally(res=>{
            localStorage.clear()
            axios.post(`${process.env.REACT_APP_AUTH_URL}/session/destroy`,{
            },{
                withCredentials:true
            }).finally(res=>{
                localStorage.clear()
                history.push(url)
                resolve(res)
            })
        })
    })
    

    return promise
}