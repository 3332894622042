import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import modal from "../../Pages/community/modal/modal";
import axios from "axios";

export const saveRewardsExternal = createAsyncThunk("saveRewardsExternal", async (data, {rejectWithValue}) => {
    const url = `${modal.postReward}`
    const response = await axios.post(url, data);
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

export const detailRewardExternal = createAsyncThunk("detailRewardExternal", async(rewardId, {rejectWithValue}) => {
    const url = `${modal.getDetailReward}?rewardId=${rewardId}`
    const response = await axios.get(url)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue
    }
})

export const updateRewardExternal = createAsyncThunk("updateRewardExternal", async(data, {rejectWithValue}) => {
    const url = modal.updateReward
    const response = await axios.post(url, data)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})
export const RewardSlice = createSlice({
    name : 'RewardSlice',
    initialState : {
        loading : false,
        detailReward : {},
    },
    extraReducers : (builder) => {
        builder.addCase(saveRewardsExternal.pending, (state, action) => {
            state.loading = true
        }).addCase(saveRewardsExternal.fulfilled, (state, action) =>{
            state.loading = false
        }).addCase(saveRewardsExternal.rejected, (state, action) => {
            state.loading = false
        });

        builder.addCase(detailRewardExternal.pending, (state, action) => {
            state.loading = true
        }).addCase(detailRewardExternal.fulfilled, (state, action) =>{
            state.loading = false
            state.detailReward = action.payload.data
        }).addCase(detailRewardExternal.rejected, (state, action) => {
            state.loading = false
        });

        builder.addCase(updateRewardExternal.pending, (state, action) => {
            state.loading = true
        }).addCase(updateRewardExternal.fulfilled, (state, action) =>{
            state.loading = false
        }).addCase(updateRewardExternal.rejected, (state, action) => {
            state.loading = false
        });
    }
})

export default RewardSlice.reducer