import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: {
    },
    userCommunity : {}
  },
  reducers: {
    setData: (state,action) => {
      state.value = action.payload
      // let promise = new Promise(function(resolve,reject){
      //   resolve('a')
      // })
      // return promise
    },
    setDataUserCommunity : (state, action)=> {
      state.userCommunity = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setData, setDataUserCommunity } = userSlice.actions

export default userSlice.reducer
