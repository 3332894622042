const CustomButtonBasic = (props) => {
    var type = 'button'
    if(props.type == 'submit'){
        type='submit'
    }

    // var background = props.background
    var text = props.text
    var size = props.size

    if(props.outline == '1'){
        if(props.background=='primary'){
            var border='border-[#B2292C] hover:bg-[#B61E23]'
            var text ='text-[#B2292C] hover:text-white'
        }else{
            var border='border-[#B2292C] hover:bg-[#B61E23]'
            var text ='text-[#B2292C] hover:text-white'
        }
        return (
            <>  
                <button onClick={props.onClick} type={type} className={`outline-button  border ${border} bg-white ${text} w-${size} px-7 py-1 rounded-md text-sm md:text-lg font-semibold `}>{props.children}</button>
            </>
        )
        
    } else{
        if(props.background=='primary'){
            var bg='bg-[#B2292C] hover:bg-[#8a2d30]'
        }else{
            var bg='bg-[#B2292C] hover:bg-[#8a2d30]'
        }
        return (
            <>  
                <button onClick={props.onClick} type={type} className={`${bg} btn-sm md:btn-lg text-white w-${size} px-7 py-1 rounded-md text-sm md:text-lg font-semibold ${props.className}`}>{props.children}</button>
            </>
        )
    }
    


}
export default CustomButtonBasic