import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userSlice from './Slice/user'
import CommunitySlice from './features/CommunitySlice';
import TwibbonSlice from './features/TwibbonSlice'

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore ,FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
import EventSlice from './features/EventSlice';
import RewardSlice from './features/RewardSlice';
import LocationSlice from './features/LocationSlice';
import DashboardSlice from './features/DashboardSlice';
  
const persistConfigUser = {
    key: 'user',
    storage,
}

const persistConfigRacRegistration = {
  key : 'EventSlice',
  storage,
}

const userReducer = persistReducer(persistConfigUser, userSlice)
const EventSliceReducer = persistReducer(persistConfigRacRegistration, EventSlice)

export const store = configureStore({
    reducer: {
      user:userReducer,
      community: CommunitySlice,
      twibbon : TwibbonSlice,
      raceRegistration : EventSliceReducer,
      rewards : RewardSlice,
      location: LocationSlice,
      dashboard: DashboardSlice,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }),
  })
  
  export const persistor = persistStore(store)
  
