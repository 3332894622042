import { library } from '@fortawesome/fontawesome-svg-core'
import { fab,faInstagram,faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faAngleLeft, faAngleRight, faBars, faCheckSquare
    , faCoffee, faPencilAlt, faTimes,faSyncAlt, faRunning
    , faFlag ,faEllipsisV, faInfoCircle, faPhone,faEnvelope
    , faMapMarkerAlt,faSpinner
    ,faTachometerAlt,faDumbbell
    ,faCalendarDay,faTrash
    ,faHome
    ,faCog
} from '@fortawesome/free-solid-svg-icons'


library.add(faTrash,fab,faInstagram, faWhatsapp, faCheckSquare, faCoffee , faBars, faSyncAlt, faRunning,faCalendarDay,faEnvelope,
    faFlag, faMapMarkerAlt,faTachometerAlt,faHome,faCog,faEllipsisV,faInfoCircle, faPhone,
    faTimes,faAngleRight,faAngleLeft,faPencilAlt,faSpinner,faDumbbell)
