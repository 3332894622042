import axios from "axios";
import toast from "./Utility/toast"

import {store} from "../Redux/store";
import { setData } from '../Redux/Slice/user'

import { createBrowserRouter } from "react-router-dom";

import history from "../history";

let instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers:{
        // Authorization:'Bearer '+JSON.parse(JSON.parse(localStorage.getItem("persist:user")).value).token
    }
})
if(localStorage.getItem("persist:user") != undefined && JSON.parse(JSON.parse(localStorage.getItem("persist:user"))?.value)?.token){
    instance.defaults.headers.common['Authorization'] = 'Bearer '+JSON.parse(JSON.parse(localStorage.getItem("persist:user")).value).token
}

instance.interceptors.response.use(res=>{
//for 200
    return res;
},
res=>{
//for 400-500
// console.log(JSON.parse(JSON.parse(localStorage.getItem("persist:user")).value).access_token)
if(!res.response){
    res.response = {}
    res.response.data = {}
    res.response.data.message = 'Something Wrong'
}    
    if(res.response.status == 401){
        history.push('/login?expired=true&goto='+window.location.href.split('?')[0])
        store.dispatch(setData({}))
        localStorage.removeItem('_a7as__tt56uid')
        localStorage.removeItem('__xtropt')
        // toast('Session Expired')
        // localStorage.removeItem('persist:user')
    }else{
        toast(res.response.data.message)
        // console.log(res)
    }
    throw res;
    // console.log(res)
})


export default instance

