import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import instance from "../../Services";
import { setData } from "../Slice/user";

export const getRaceRegistrationTemplate = createAsyncThunk("getRaceRegistrationTemplate", async ({raceCodeName, step}, {rejectWithValue}) => {
    const url = `/api-race/getRaceRegistrationTemplate?raceCode=${raceCodeName}&step=${step}`
    const response = await instance.get(url)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

export const getRaceRegistrationTemplateSecond = createAsyncThunk("getRaceRegistrationTemplateSecond", async ({raceCodeName, step}, {rejectWithValue}) => {
    const url = `/api-race/getRaceRegistrationTemplate?raceCode=${raceCodeName}&step=${step}`
    const response = await instance.get(url)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

export const saveRegistrationEvent = createAsyncThunk("saveRegistrationEvent", async (data, {rejectWithValue}) =>{
    const url = `/api-race/saveRegistration`
    const response = await instance.post(url, data);
    try{
        const result = response.data
        return result
    }catch(e) {
        return rejectWithValue(e)
    }
})

export const checkingUserStep = createAsyncThunk("checkingUserStep", async(res, {rejectWithValue}) => {
    const url = '/api-race/checkingUserStep'
    const response = await instance.post(url, res);
    try{
        const result = response.data
        return result
    }catch(e) {
        return rejectWithValue(e)
    }
})

export const getDataShowSummary = createAsyncThunk("getDataShowSummary", async(res, {rejectWithValue}) => {
    const url = `/api-race/getDataShowSummary?raceCode=${res}`
    const response = await instance.get(url);
    try{
        const result = response.data
        return result
    }catch(e) {
        return rejectWithValue(e)
    }
})

const EventSlice = createSlice({
    name : 'EventSlice',
    initialState : {
        raceRegistrationField : {},
        loading : false,
        error : null,
        race : null,
        raceTemplate : null,
        raceCategory : [],
        valueFirstStep: [],
        valueFirstStepObject : {},
        valueSecondStep : [],
        valueSecondStepObject : {},
        resUser : {},
        raceRegistrationFieldSecond : {},
        valueRegister : [],
        showSummary: [],
    },
    reducers : {
        setDataFirstStep : (state, action) => {
            const result = action.payload
            var res = Object.keys(result).map(function(name){
                var obj = {};
                obj[name] = result[name];
                return obj;
            });
            state.valueFirstStep = res
            state.valueFirstStepObject = result
        },
        setDataSecondStep : (state, action) => {
            const result = action.payload
            var res = Object.keys(result).map(function(name){
                var obj = {};
                obj[name] = result[name];
                return obj;
            });
            state.valueSecondStep = res
            state.valueSecondStepObject = result
        },
        setValueRegister: (state, action) => {
            const { data, totalPage, currentStep } = action.payload;
            data.map((res) => {
                var curIndex = null
                state.valueRegister.map((vr, index) => {
                    if(vr.name == res.name){
                        curIndex = index
                    }
                })
                if(curIndex != null){
                    state.valueRegister[curIndex].value = res.value
                }else{
                    state.valueRegister.push({
                        'name' : res.name,
                        'value' : res.value,
                    })
                }
            })
        }
    },
    extraReducers:(builder) => {
        builder.addCase(getRaceRegistrationTemplate.pending, (state, action) => {
            state.loading = true
        }).addCase(getRaceRegistrationTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.raceRegistrationField = action.payload.data
            state.race = action.payload.data.race_data
            state.raceTemplate = action.payload.data.race_template
            state.raceCategory = action.payload.data.category
        }).addCase(getRaceRegistrationTemplate.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(getRaceRegistrationTemplateSecond.pending, (state, action) => {
            state.loading = true
        }).addCase(getRaceRegistrationTemplateSecond.fulfilled, (state, action) => {
            state.loading = false;
            state.raceRegistrationFieldSecond = action.payload.data
            state.race = action.payload.data.race_data
            state.raceTemplate = action.payload.race_template
            state.raceCategory = action.payload.data.category
        }).addCase(getRaceRegistrationTemplateSecond.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(saveRegistrationEvent.pending, (state, action) => {
            state.loading = true
        }).addCase(saveRegistrationEvent.fulfilled, (state, action) => {
            state.loading = false
            state.valueRegister = []
        }).addCase(saveRegistrationEvent.rejected, (state, action) => {
            state.loading = false
        });

        builder.addCase(checkingUserStep.fulfilled, (state, action) => {
            state.loading = false;
            state.resUser = action.payload.user
            setData(action.payload.user)
        });

        builder.addCase(getDataShowSummary.pending, (state, action) => {
            state.loading = true
        }).addCase(getDataShowSummary.fulfilled, (state, action) => {
            state.loading = false;
            state.showSummary = action.payload.data.show_summary
        }).addCase(getDataShowSummary.rejected, (state, action) => {
            state.loading = false;
        });
    }
})

export const {setDataFirstStep, setDataSecondStep, setValueRegister} = EventSlice.actions
export default EventSlice.reducer