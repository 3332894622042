var urlApiCommunity = `${process.env.REACT_APP_API_URL}/api-community`;
var urlApiStore = `${process.env.REACT_APP_API_URL}/api-store`;
// var urlStore = 'http://192.168.8.101:3001';

export default {
    urlImageDir : 'cause.monster/img',
    urlImage : 'https://img.cause.monster',
    login : `${urlApiCommunity}/login`,
    loginV2 : `api-community/login`,
    insert_event : `${urlApiCommunity}/insert_event`,
    postReward : `${urlApiCommunity}/post_rewards`,
    getRewards : `${urlApiCommunity}/get_rewards`,
    changeStatusReward : `${urlApiCommunity}/change_reward_status`,
    deleteReward : `${urlApiCommunity}/delete_rewards`,
    // getRewardsListCommunity : `${urlApiCommunity}/get_rewards_list_community`,
    getRewardsListCommunity : `api-community/get_rewards_list_community`,
    getPointHistory : `${urlApiCommunity}/get_point_history`,
    savePoint : `${urlApiCommunity}/save_point`,
    getEvent : `${urlApiCommunity}/get_event_per_community`,
    getEventV2 : `api-community/get_event_per_community`,
    getpointHistoryUser : `api-community/get_point_history_users`,
    getUserInformation : `api-community/get_user_information`,
    postRedeemReward : `${urlApiCommunity}/redeem_reward`,
    getBrand : `${urlApiStore}/branduser`,
    getProduct : `${urlApiStore}/get_produk_for_banner`,
    getTotalStockProductVariant : `${urlApiStore}/total_stock_product_variant`,
    getDetailReward : `${urlApiCommunity}/get_detail_reward`,
    updateReward : `${urlApiCommunity}/update_reward`,
    // detailProduct : `${urlStore}/detail-product`,
    getCommunityRedeems : `${urlApiCommunity}/get_community_redeems`,
    getDetailRedeem : `${urlApiCommunity}/get_detail_redeem`,
    resendVoucherCode : `api-community/resend_voucher`,
    deleteMemberBooking : `${urlApiCommunity}/deleteMemberBooking`,
    bulkPoint : `${urlApiCommunity}/bulk_point`,
    bookingEvent : `api-community/book/code`,
    cancelBooking : `${urlApiCommunity}/cancelBooking`,
    detailCommunity : 'api-community/detail_community',
    getMemberList : 'api-community/get_member_list',
    checkRole: '/check/role',
}