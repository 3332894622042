import { useLocation,useNavigate, useSearchParams } from "react-router-dom";
import {useState, useEffect } from 'react';

import './Assets/css/App.css';
import RouteConfig from './Route';

import $ from 'jquery';
import { ModalProvider } from "react-modal-hook";
import IdleTimer from './Components/IdleTimer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Helmet} from "react-helmet";
import ReactGA from 'react-ga';
function App() {
  //instantiate navigate
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  //instantiate toast
  const notify = (message) => {
    toast(message, {
      position: toast.POSITION.TOP_CENTER,
      className:'bg-red-500 font-semibold text-center text-sm py-0 text-white',
    });
  };

  //title management
  const defaultTitle = 'Cause Virtual Run & Ride'
  const [title,setTitle]= useState(defaultTitle)
  const location = useLocation();
  useEffect(() => {
    var textarr = location.pathname.split('/')
    var community = ['pointhistory','rewards','act']
    if(location.hash == ''){
      if(!(textarr[3] && community.includes(textarr[3]))){
        window.scrollTo(0,0);
      }
    }else{
      $(location.hash).scroll();
    }

    //check if expired
    // var expiredTime = parseInt(localStorage.getItem("_expiredTime") || 0,10)
    // if(expiredTime < Date.now()){
    //   if(localStorage.getItem('__xtropt') != undefined){
    //     localStorage.removeItem('__xtropt')
    //     localStorage.removeItem('_a7as__tt56uid')
    //     localStorage.removeItem('_aas8_ipigtxx=1')
    //     navigate("/login", { replace: true })
    //     notify("Session Expired")
    //   }
    // }
  }, [location]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);
  if(searchParams.get('mobile') == 'true'){
    return (
      <ModalProvider>
      <div className="App" id='App'>
          <ToastContainer 
          autoClose={1000} 
          closeButton={false}
          theme='colored'
          className='h-5'
          />
          <RouteConfig/>          
      </div>
      </ModalProvider>
    )
  }else{
    return (
      <IdleTimer>
        <ModalProvider>
          <div className="App" id='App'>
              <ToastContainer 
              autoClose={1000} 
              closeButton={false}
              theme='colored'
              className='h-5'
              />
              <RouteConfig/>          
          </div>
        </ModalProvider>
      </IdleTimer>
    )
  }
}

export default App;
