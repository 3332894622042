import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../Services";

export const getListTwibbon = createAsyncThunk('twibbon/getListTwibbon', async (raceCode) => {
    const url = `/framework/api-race/twibbon?raceCode=${raceCode}`
    const response = await instance.get(url)
    return response.data
})

export const saveTwibbonDownload = createAsyncThunk('twibbon/saveTwibbonDownload', async(initialState) => {
    const url = '/framework/api-race/twibbon-save'
    const response = await instance.post(url, initialState)
    return response.data
})

export const getTotalDownloadTwibbon = createAsyncThunk('twibbon/getTotalDownloadTwibbon', async({twibbonId, raceName}) => {
    const initialState = {
        'twibbonId' : twibbonId,
        'raceCode' : raceName
    };
    const url = `/framework/api-race/twibbon-total-download`
    const response = await instance.post(url, initialState)
    return response.data
})

const TwibbonSlice = createSlice({
    name : 'twibbon',
    initialState : {
        twibbonData : [],
        error : null,
        loading : true,
        isRegistered : true,
        selectedTwibbon : null,
        downloadTwibbon: null,
        twibbonExpired : null,
    },
    extraReducers(builder) {
        builder.addCase(getListTwibbon.pending, (state, action) => {
            state.loading = true
        }).addCase(getListTwibbon.fulfilled, (state, action) => {
            state.loading = false
            state.twibbonData = action.payload.twibbon
            if(action.payload.twibbon.length >0){
                state.selectedTwibbon = action.payload.twibbon[0]
                state.twibbonExpired = action.payload.twibbon[0].twibbon_expiry
            }
            state.isRegistered = action.payload.isRegistered
        }).addCase(getListTwibbon.rejected, (state, action) => {
            state.loading = false
        }).addCase(getTotalDownloadTwibbon.fulfilled, (state, action) => {
            state.downloadTwibbon = action.payload.message
        })
    },
    reducers: {
        setSelectedTwibbon: (state,action) => {
          state.selectedTwibbon = action.payload
          // let promise = new Promise(function(resolve,reject){
          //   resolve('a')
          // })
          // return promise
        }
      },
})

const {reducer} = TwibbonSlice
export const { setSelectedTwibbon } = TwibbonSlice.actions
export default reducer