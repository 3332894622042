import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../Services";

export const getProvince = createAsyncThunk("getProvince",async (_, {rejectWithValue}) => {
    const url = `/api-other/provincedbv2`
    const response = await instance.get(url)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

export const getCity = createAsyncThunk("getCity", async(provinceId, {rejectWithValue}) => {
    const resProvinceId = provinceId === undefined ? null : provinceId
    const url = `/api-other/citydb?provinceId=${resProvinceId}`
    const response = await instance.get(url)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

export const getSubUrb = createAsyncThunk("getSubUrb", async(cityId, {rejectWithValue}) => {
    const resCityId = cityId === undefined ? null : cityId
    const url = `/api-other/suburbdb?cityId=${resCityId}`
    const response = await instance.get(url)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

export const getArea = createAsyncThunk("getArea", async(subUrbId, {rejectWithValue}) => {
    const resSubUrbId = subUrbId === undefined ? null : subUrbId
    const url = `/api-other/areadb?suburb=${resSubUrbId}`
    const response = await instance.get(url)
    try{
        const result = response.data
        return result
    }catch(e){
        return rejectWithValue(e)
    }
})

const LocationSlice = createSlice({
    name : 'location',
    initialState : {
        province :[],
        loadingProvince : false,
        city : [],
        loadingCity: false,
        subUrb : [],
        loadingSub : false,
        area : [],
        loadingArea : false,
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getProvince.pending, (state, action) => {
            state.loadingProvince = true
        }).addCase(getProvince.fulfilled, (state, action) => {
            state.loadingProvince = false
            state.province = action.payload.data
        }).addCase(getProvince.rejected, (state, action) => {
            state.loadingProvince = false
        });

        builder.addCase(getCity.pending, (state, action) => {
            state.loadingCity = true
        }).addCase(getCity.fulfilled, (state, action) => {
            state.loadingCity = false
            state.city = action.payload.data
        }).addCase(getCity.rejected, (state, action) => {
            state.loadingCity = false
        });

        builder.addCase(getSubUrb.pending, (state, action) => {
            state.loadingSub = true
        }).addCase(getSubUrb.fulfilled, (state, action) => {
            state.loadingSub = false
            state.subUrb = action.payload.data
        }).addCase(getSubUrb.rejected, (state, action) => {
            state.loadingSub = false
        });
        
        builder.addCase(getArea.pending, (state, action) => {
            state.loadingArea = true
        }).addCase(getArea.fulfilled, (state, action) => {
            state.loadingArea = false
            state.area = action.payload.data
        }).addCase(getArea.rejected, (state, action) => {
            state.loadingArea = false
        });
    }
})

export default LocationSlice.reducer